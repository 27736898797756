<template>
    <section>
    <div class="container-fluid">
        <div class="container">
            <div class="content" v-html="content"></div>
        </div>
    </div>
    </section>
</template>
<script>
import PagesService from '@/_services/pages.service';

const pagesService = new PagesService();
export default {
  name: 'cms',
  data() {
    return {
      page_id: this.$route.params.seo,
      content: '',
    };
  },
  components: {

  },
  created() {
    this.changedUrl(this.$route.params.seo);
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.seo': function (value) {
      this.changedUrl(value);
    },
  },
  methods: {
    changedUrl(page) {
      this.page_id = page;
      this.loadStuff();
    },
    loadStuff() {
      if (pagesService.isCached(`CMS_${this.page_id}`)) {
        this.content = pagesService.getCached(`CMS_${this.page_id}`).content;
        document.title = pagesService.getCached(`CMS_${this.page_id}`).title;
      } else {
        this.$store.state.loading = true;
      }

      pagesService.getCmsPage(this.page_id).then((page) => {
        if (!page.content) {
          this.$router.push('/404');
        }
        this.content = page.content;
        document.title = page.title;
        this.$store.state.loading = false;
      });
    },
  },
};
</script>
